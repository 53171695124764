
import { defineComponent, onMounted, ref } from "vue";
import { config } from "@/core/helpers/config";
import { themeName } from "@/core/helpers/documentation";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import RecruitmentsHistory from "@/components/recruitments/RecruitmentsHistory.vue";
// import BalanceDetails from "@/components/cards/BalanceDetails.vue";
// import CompanyPolicy from "@/components/companypolicy/CompanyPolicy.vue";
// import ECShopAdvisory from "@/views/ECShopAdvisory.vue";

export default defineComponent({
  name: "recruitments",
  components: {
    RecruitmentsHistory,
    // BalanceDetails,
    // CompanyPolicy,
    // ECShopAdvisory
  },
  setup() {
    const tabIndex = ref(0);

    onMounted(() => {
      // set the tab from previous
      tabIndex.value = parseInt(localStorage.getItem("recruitmentsTab") || "0");
      const pt = String(localStorage.getItem("pt"));
      if(pt === "ovi"){
        setCurrentPageTitle("Digital Partners");
      } else { 
        setCurrentPageTitle("Recruitments");
      }
    });

    
  }
});
