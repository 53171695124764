
import { defineComponent } from "vue";
import {loadPaginatedRecruitment}  from "@/api/recruitments";

export default defineComponent({
  name: "recruitmenthistory", 
  methods: {
    async recruitmentshistory(){ 
      this.loading = true;
      let params = {"page" : 1, "f" : this.from_date + " 00:00:00","t" : this.to_date + " 23:59:59"}
      const response = await loadPaginatedRecruitment(params);
      this.list = response.data.pageList;
      this.recruitment_length = response.data.maxcount;
      this.loading = false;
    },
  },
  mounted(){
      this.recruitmentshistory();
  },
  data(){
      return { 
        list : [],
        loading: true,
        to_date :  new Date().toISOString().substr(0, 10),
        from_date: new Date().toISOString().substr(0, 10),
        recruitment_length: 0
      }
  },
  components: {
  },
  props: {
    widgetClasses: String
  }
});

